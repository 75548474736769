@import "Styles/_colors.scss";

.UNFIStats {
  padding: 1.5rem;
  border: 1px solid $lightest-grey;
  border-top: 5px solid $primary-theme;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 23rem;
  min-width: 23rem;

  @media only screen and (max-width: 480px) {
    min-width: auto;
    width: 100%;
  }

  &__title {
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
  }

  &__body {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    &__row {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0.25rem 0;

      &__title {
        text-align: center;
      }

      &__amount {
        margin-top: 1rem;
        font-weight: 600;
        font-size: 2rem;
        text-align: center;
        word-break: break-all;
        line-height: 2rem;
      }
    }
  }
}
