.staking-wallets-header {
  font-size: large;
  text-indent: 0.5rem;
  font-weight: 600;
}

.wallet-icon {
  border-radius: 100%;
  overflow: hidden;
}
