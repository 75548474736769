$dark-purple: #3b3174;
$light-purple: #626eb0;
$purple-gradient: linear-gradient(180deg, $light-purple, $dark-purple);

$grey: #292929;
$dark-grey: darken(#292929, 10%);
$light-grey: lighten(#292929, 40%);
$lightest-grey: lighten(#292929, 75%);

$blue: #1da3c9;
$blue-gradient: linear-gradient(to right top, $blue, lighten($blue, 15%));

$green: #00e676;
$green-2: darken($green, 20%);
$dark-green: darken($green, 40%);
$light-green: lighten($green, 30%);

$pink: #fa9191;
$dark-pink: darken(#fa9191, 15%);
$light-pink: lighten(#fa9191, 5%);

$primary-theme: $green;
$secondary-theme: $light-grey;
$complementary-theme: $grey;

$box-shadow-1: 0 5px 20px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
