@import "Styles/_colors.scss";

.MyDashboard {
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 480px) {
    padding-top: 0rem;
  }

  &__class-tokens {
    position: absolute;
    z-index: 2;
    top: -5rem;
    left: 0;
    margin: 0 12vw;
    height: 10rem;
    padding: 1.5rem;
    width: calc(100% - 24vw);
    color: #fff;
    background: $complementary-theme;
    box-shadow: $box-shadow-1;

    @media only screen and (max-width: 480px) {
      margin: 0 1%;
      width: 98%;
      position: relative;
      height: 100%;
    }

    &__title {
      font-weight: 600;
      margin-bottom: 1rem;
    }

    &__classes {
      display: flex;

      @media only screen and (max-width: 480px) {
        flex-direction: column;
      }

      > div {
        width: 100%;

        .Class {
          display: flex;

          &__shape {
            width: 2.5rem;
            margin: 0 1rem;

            img {
              width: 100%;
              height: auto;
            }
          }

          &__body {
            flex: 1;

            &__balance {
              font-size: 1.5rem;
              font-weight: 600;
              word-break: break-all;
            }

            &__description {
              margin: 0.25rem 0;
              font-weight: 600;
              font-size: 0.7rem;
              color: $lightest-grey;
            }

            &__action {
              padding: 0.75rem 0;

              span {
                margin-right: 0.5rem;
                border-radius: 4px;
                border: 1px solid $primary-theme;
                font-size: 0.8rem;
                font-weight: 300;
                padding: 0.1rem 0.5rem;
                cursor: pointer;
                transition: background 0.3s;

                &:hover {
                  background: $primary-theme;
                  color: #000;

                  &.disabled {
                    background: $light-grey;
                    border: 1px solid $light-grey;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__content {
    border: 1px solid $lightest-grey;
    background: #fff;

    &__tabs {
      display: flex;
      border-bottom: 1px solid lighten($lightest-grey, 5%);

      &__tab {
        position: relative;
        color: $grey;
        font-weight: 600;
        padding: 1rem 2rem;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        transition: border 0.3s;

        &::before {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          height: 100%;
          width: 1px;
          background-color: $lightest-grey;
        }

        &.active {
          border-bottom: 2px solid $primary-theme;
        }

        &:hover:not(.active) {
          border-bottom: 2px solid $light-green;
        }
      }
    }

    &__body {
      padding: 3rem 2rem;

      &.CLAIM,
      &.STAKE,
      .StakingWrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
      }

      .StakingWrapper {
        @media only screen and (max-width: 480px) {
          flex-direction: column;

          > div {
            margin: 1rem 0 !important;
          }
        }

        > div {
          margin: 2rem 1rem;
        }
      }
    }
  }
}
