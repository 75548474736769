@import "Styles/_colors.scss";

.Unstake {
  padding: 1.5rem;
  border: 1px solid $lightest-grey;
  border-top: 5px solid $primary-theme;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 23rem;
  min-width: 23rem;

  @media only screen and (max-width: 480px) {
    min-width: auto;
    width: 100%;
  }

  &__title {
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
  }

  &__body {
    margin: 3rem 0;

    > span {
      display: block;
    }

    &__amount {
      font-weight: 600;
      font-size: 2rem;
      text-align: center;
      word-break: break-all;
      line-height: 2rem;
    }

    &__amount-title {
      text-align: center;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__available {
      margin: 0.25rem;
    }
  }

  &__submit {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;

    > button {
      margin: 0 0.25rem;
    }
  }
}
