.WarningAlert {
  background-color: #ffe699;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 2px solid #ffdb70;
  font-weight: 600;
  font-size: large;
  padding: 0.5rem;
  text-align: center;
}
