@import "Styles/_colors.scss";

.MyDashboardHeader {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  // background: url("../../Assets/unifi-bg.png") top right;
  background-repeat: no-repeat;
  background-size: 40%;

  > div {
    width: 50%;
  }

  &__left {
    &__back {
      svg {
        cursor: pointer;
        transform: rotate(180deg);
        fill: $primary-theme;
        width: 2rem;
        height: auto;
      }
    }

    &__title {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    &__body {
      margin-top: 2.5rem;
      margin-bottom: 4rem;

      &__balance-amount {
        color: $primary-theme;
        font-size: 2rem;
        padding: 0.25rem 0;
        word-break: break-all;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    background-size: 100%;
    background-position: bottom right;

    &__left {
      width: 100% !important;
    }
  }
}
