@import "Styles/_colors.scss";

.Input-Wrapper {
  position: relative;

  .Input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: 0;
    border-radius: 5px;
    padding: 0.4rem 0.3rem;
    text-align: center;

    &.max {
      padding-right: 3rem;
    }
  }

  &__max {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translate(0, -50%);
    color: rgba(0, 0, 0, 0.2);
    font-weight: 600;
    cursor: pointer;
  }
}
