@import "Styles/_colors.scss";

.UnfiGovernanceHeader {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  background: url("../../Assets/unifi-bg.png") top right;
  background-repeat: no-repeat;
  background-size: 40%;

  > div {
    width: 50%;
  }

  &__left {
    &__title {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    &__body {
      font-weight: 300;
      text-align: justify;

      a {
        text-decoration: none;
        color: $primary-theme;
      }
    }

    &__more-info {
      margin-top: 2rem;
    }
  }

  @media only screen and (max-width: 480px) {
    background-size: 100%;
    background-position: bottom right;

    &__left {
      width: 100% !important;
    }
  }
}
