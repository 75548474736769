@import "Styles/_colors.scss";

.UnfiGovernance {
  display: flex;
  flex-direction: column;

  .pagination-wrapper {
    margin: 20px auto;
    .Mui-selected {
      background: $green;
      &:hover{
        background: $green;
        cursor: default;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    padding-top: 0rem;
  }

  &__content {
    border: 1px solid $lightest-grey;
    background: #fff;

    &__tabs {
      display: flex;
      border-bottom: 1px solid lighten($lightest-grey, 5%);

      &__tab {
        color: $grey!important;
        font-weight: 600;
        padding: 1rem 2rem;
        text-decoration: none!important;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        transition: border 0.3s;

        &.active {
          border-bottom: 2px solid $primary-theme;
        }

        &:hover:not(.active) {
          border-bottom: 2px solid $light-green;
        }
      }
    }

    &__body {
      &.CLAIM,
      &.STAKE {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
