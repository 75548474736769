@import "Styles/_colors.scss";

.ConnectedModal {
  width: 25rem;

  &__header {
    padding-bottom: 1rem;
    padding: 1rem 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;

    &__close {
      cursor: pointer;
    }
  }

  &__body {
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;

    &__address {
      display: flex;
      justify-content: center;

      a {
        color: #000;
        font-size: 0.9rem;
        word-wrap: break-word;
      }
    }

    &__balances {
      margin-top: 1rem;

      &__balance {
        margin: 0.5rem 0;

        .title, .balance {
          display: inline-block;
          vertical-align: middle;

        }
        .title {
          width: 5rem;
          font-size: 1rem;
          font-weight: 700;
          color: $light-grey;
          padding-left: 10px;
          & span {
            width: 30px;
            text-align: center;
            display: inline-block;
            & img {
              width: auto;
              height: 30px;
              vertical-align: middle;
              margin: 0 auto;
              max-width: 100%;
            }
          }
        }

        .balance {
          width: 14rem;
          border-radius: 5px;
          word-break: break-all;
          font-size: 1.3rem;
          font-weight: 700;
          color: $grey;
          text-align: right;
          background: #efefef;
          padding: 5px 10px;
        }
      }
    }

    &__logout {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}
