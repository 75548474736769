@import "Styles/_colors.scss";

.Proposals {
  position: relative;

  &__create {
    position: absolute;
    top: -2.5rem;
    right: 1rem;
  }

  &__row {
    border-bottom: 1px solid lighten($lightest-grey, 5%);
    padding: 1rem 4rem;
    cursor: pointer;

    @media only screen and (max-width: 480px) {
      padding: 1rem;
    }

    &:hover {
      .Proposals__row__bottom {
        color: $dark-grey;
      }
    }

    &__top {
      font-weight: 600;
      font-size: 1.1rem;
    }

    &__bottom {
      color: $light-grey;
      padding: 0.75em 0;
      font-size: 0.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: 0.2s color;

      > div {
        display: flex;
        align-items: center;
      }

      span {
        margin-right: 1rem;

        &.status {
          padding: 0 0.5rem;
          height: 2rem;
          text-align: center;
          border-radius: 5px;
          font-weight: 600;
          border: 1px solid $primary-theme;
          display: flex;
          justify-content: center;
          align-items: center;

          &.closed {
            border: 1px solid $light-grey;
          }

          &.open {
            background-color: $primary-theme;
            color: #000;
          }

          > span {
            margin: 0;
            padding: 0 0.25rem;
          }
        }

        &.date {
          margin-left: 2rem;
        }
      }
    }
  }
}
