@import "Styles/_colors.scss";

.Home {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 2rem;
    font-weight: 700;
    width: 60%;
    text-align: center;
  }

  &__subtitle {
    font-size: 1.2rem;
    font-weight: 400;
    width: 60%;
    text-align: center;
  }

  &__items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    &__item {
      padding: 2rem;
      width: 20rem;
      height: 20rem;
      border-radius: 5px;
      margin: 0 0.5rem;
      border: 1px solid $lightest-grey;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;

      &.my-dashboard {
        background: $primary-theme;

        .Home__items__item__arrow {
          svg {
            fill: #fff;
          }
        }
      }

      @media only screen and (max-width: 480px) {
        margin: 0.5rem;
      }

      &__icon {
        height: 2.5rem;

        svg {
          height: 100%;
          width: auto;
          fill: $primary-theme;
        }
      }

      &__title {
        width: 70%;
        font-size: 1.5rem;
        font-weight: 700;
      }

      &__list {
        .list__li {
          margin: 0.25rem 0;
          display: flex;
          justify-content: space-between;
        }
      }

      &__body {
        text-align: justify;
      }

      &__arrow {
        svg {
          height: auto;
          width: 2rem;
          fill: $primary-theme;
        }
      }
    }
  }

  .Staking {
    &__amount {
      font-size: 1.75rem;
      font-weight: 700;
      text-align: center;
      word-break: break-word;
    }

    &__amount-title {
      font-size: 0.8rem;
      color: $secondary-theme;
      text-align: center;
    }
  }

  .Stake {
    margin-top: 1.5rem;

    &__available {
      text-align: center;
    }

    &__input {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0;
    }
  }

  .Unclaimed {
    &__amount {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
    }

    &__label {
      font-size: 0.8rem;
      color: $secondary-theme;
      text-align: center;
    }

    &__user-total {
      margin-top: 1rem;
      font-size: 0.8rem;
      color: $secondary-theme;
      text-align: center;
    }
  }

  @media only screen and (max-width: 480px) {
    &__items {
      flex-direction: column;
    }
  }
}
