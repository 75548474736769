@import "Styles/_colors.scss";

.Header {
  padding: 0.25rem 12vw;
  background: $complementary-theme;
  color: #fff;

  a:not(.Button) {
    color: $primary-theme!important;
  }
  
  &__info {
    display: flex;
    justify-content: space-between;

    &__logo {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        height: 3rem;
        width: auto;
      }

      > span {
        border-left: 1px solid #fff;
        height: 1.5rem;
        margin: 0 1rem;
        padding: 0 1rem;

        display: flex;
        align-items: center;
        color: #fff;

        @media only screen and (max-width: 480px) {
          border-left: none;
        }
      }
    }

    &__right-menu {
      display: flex;
      align-items: center;

      > span {
        margin: 0 0.5rem;
      }

      &__links {
        display: flex;
        align-items: center;
        margin-right: 1rem;

        > span {
          cursor: pointer;
          margin-left: 1.5rem;
          display: flex;
          align-items: center;

          > svg {
            fill: $primary-theme;
            margin-right: 0.5rem;
            width: 1rem;
            height: auto;
          }
        }
      }

      &__address {
        padding: 0.25rem 0.5rem;
        border: 2px solid $secondary-theme;
        border-radius: 4px;
        width: 15rem;
        text-align: center;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;

        &.connected {
          border-color: $primary-theme;
        }
      }
    }
  }

  &__children {
    > div {
      padding: 4rem 0;
    }
  }

  @media only screen and (max-width: 480px) {
    &__info {
      flex-direction: column;

      > span {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      &__right-menu {
        margin-top: 1rem;

        > span {
          margin: 1rem 0;
        }
      }
    }

    &__children {
      > div {
        padding: 1rem 0;
      }
    }
  }
}
