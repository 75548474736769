@import "Styles/_colors.scss";

.App {
  font-size: 0.85rem;
  line-height: 1.3rem;
  * {
    box-sizing: border-box;
  }

  a {
    color: $green-2;
    font-weight: bold;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    margin: 0;
  }

  @media only screen and (max-width: 480px) {
    overflow-x: auto;

    .hide-for-mobile {
      display: none;
    }
  }
}

.ReactModal__Content {
  position: relative;
  border-radius: 0 !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    top: 0;
    left: 0;
    background: $green;
  }
}

.ReactModal__Overlay {
  z-index: 99;
}
.ReactModal__Content {
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
  padding: 0 !important;
}

.pt-7 {
  padding-top: 7rem!important;
}